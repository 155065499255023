<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <SVGIcon
                hex-color="#0f88ef"
                name="chart-bar"
                class="kt-svg-icon mt-2 mr-3"
            />

            Breakdown
            <!-- <small class="ml-3">{{ lastUpdated }}</small> -->
        </template>
        <template #left-buttons>
            <!-- what -->
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <BiliteracySealBigFive v-if="filter === 'BigFive'" :on-click="sectionClicked" />
        <BiliteracySealByEthnicity v-else-if="filter === 'ByEthnicity'" :on-click="sectionClicked" />
        <BiliteracySealByStatus v-else-if="filter === 'ByStatus'" :on-click="sectionClicked" />
        <BiliteracySealByLanguage v-else-if="filter === 'ByLanguage'" :on-click="sectionClicked" />
        <div v-else>
            Invalid Breakdown Filter
        </div>

        <div v-if="validFilter && students.length" class="kt-portlet">
            <div class="kt-portlet__body">
                <BiliteracySealHotTable />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import BiliteracySealByLanguage from '../components/BiliteracySealByLanguage.vue';
import BiliteracySealByStatus from '../components/BiliteracySealByStatus.vue';
import BiliteracySealByEthnicity from '../components/BiliteracySealByEthnicity.vue';
import BiliteracySealBigFive from '../components/BiliteracySealBigFive.vue';
import BiliteracySealHotTable from '../components/BiliteracySealHotTable.vue';

import panelMixins from '../store/mixins/panelMixins';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import * as network from '../network';
import Types from '../store/Types';

export default Vue.extend({
    name: 'BiliteracySealBreakdown',
    components: {
        GeneralSubHeader,
        BiliteracySealBigFive,
        BiliteracySealByEthnicity,
        BiliteracySealByStatus,
        BiliteracySealByLanguage,
        BiliteracySealHotTable,
    },
    mixins: [panelMixins],
    data() {
        return {
            key: 0,
            loading: false,
            students: [],
        };
    },
    computed: {
        schoolYear() {
            return this.$store.state.biliteracySeal.schoolYear;
        },
        dataPoints() {
            return this.$store.state.biliteracySeal.dataPoints;
        },
        filter() {
            return this.$route.params.filter || null;
        },
        validFilter() {
            const { filter } = this;
            if (!filter) return false;
            return ['BigFive', 'ByEthnicity', 'ByStatus', 'ByLanguage'].includes(filter);
        },
        columns() {
            return this.$store.state.database.studentDataView.columns
                .filter((c) => c.enabled && !['achievementLevel', 'badgeTitle', 'googleEmail'].includes(c.name));
        },
    },
    beforeDestroy() {
        // if (this.root) this.root.dispose();
    },
    mounted() {
        this.populateStudents();
    },
    methods: {
        populateStudents() {
            const v = this;
            network.students.ellStudents({}, (err, res) => {
                if (err) v.showError(err);
                const { students } = res;
                v.students = students;
                v.populateDataView(null);
            });
        },
        populateDataView(list) {
            const { columns, students } = this;
            const rows = (list || students)
                .sort((a, b) => {
                    if (a.lastName < b.lastName) return -1;
                    if (a.lastName > b.lastName) return 1;
                    return 0;
                })
                .map((s) => {
                    const record = columns.map((c) => {
                        const column = { ...c };
                        const { source, format } = column;
                        const student = { ...s };

                        const cell = {
                            column,
                            cellValue: '',
                            color: null,
                        };

                        if (source == 'student') {
                            cell.cellValue = format(student);
                        }

                        return cell;
                    });

                    return record;
                });

            // add the header row
            const record = columns.map((c) => {
                const column = { ...c };
                return {
                    column,
                    cellValue: column.title,
                };
            });
            rows.unshift(record);

            this.$store.commit(Types.mutations.SET_STUDENT_DATA_VIEW_ROWS, rows);
            window.scrollTo(0, 800);
        },
        sectionClicked() {
            const { students } = this;
            const shuffled = students.sort(() => 0.5 - Math.random());
            this.populateDataView(shuffled.slice(0, 10));
        },
    },
});
</script>

<style lang="scss">

.chartDiv {
    height: 700px;
    width: 100%;
}

#kt_subheader {
    left: 265px !important;
}

td {
    vertical-align: middle !important;
}
</style>
