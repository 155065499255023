var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon mt-2 mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "chart-bar" },
                }),
                _vm._v(" Breakdown "),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4",
        },
        [
          _vm.filter === "BigFive"
            ? _c("BiliteracySealBigFive", {
                attrs: { "on-click": _vm.sectionClicked },
              })
            : _vm.filter === "ByEthnicity"
            ? _c("BiliteracySealByEthnicity", {
                attrs: { "on-click": _vm.sectionClicked },
              })
            : _vm.filter === "ByStatus"
            ? _c("BiliteracySealByStatus", {
                attrs: { "on-click": _vm.sectionClicked },
              })
            : _vm.filter === "ByLanguage"
            ? _c("BiliteracySealByLanguage", {
                attrs: { "on-click": _vm.sectionClicked },
              })
            : _c("div", [_vm._v(" Invalid Breakdown Filter ")]),
          _vm.validFilter && _vm.students.length
            ? _c("div", { staticClass: "kt-portlet" }, [
                _c(
                  "div",
                  { staticClass: "kt-portlet__body" },
                  [_c("BiliteracySealHotTable")],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }